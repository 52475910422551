import React, { useState, useReducer, Suspense } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useSetting } from "../hooks/Hooks";
import Skeleton from "@mui/material/Skeleton";
import CryptoJS from "crypto-js";

const Main = () => {
  const { globalData } = useSetting();

  const [myglobalData, setMyglobalData] = React.useState(globalData);
  const [prevScreen, setPrevScreen] = React.useState("");

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  }));

  // app startup
  const [appInitialised, setAppInitialised] = useState(false);

  // navigation
  function loadDefault() {
    if (appInitialised === false) {
      // check last active
      if (localStorage.getItem("currScreen") !== null) {
        if (
          localStorage.getItem("currScreen").length === 0 ||
          localStorage.getItem("currScreen") === ""
        ) {
          localStorage.setItem("currScreen", "Login");
        }
        let currScreen = localStorage.getItem("currScreen");
        dispatch({ event: currScreen });
      } else {
        dispatch({ event: "Login" });
      }
    }
  }

  function setLocalData(key, value) {
    let CryptoJSKey = globalData.CompanyName + "@" + globalData.Version;
    var user_data = CryptoJS.AES.encrypt(value, CryptoJSKey).toString();
    localStorage.setItem(key, user_data);
  }

  const componentConfig = {
    // Map event names to their corresponding components
    Login: myglobalData.login.path,
    changepassword: myglobalData.pages.changepassword,

    dashboard: myglobalData.pages.dashboard,
    hrdashboard: myglobalData.pages.hrdashboard,

    source: myglobalData.pages.dynamicfields.source,
    projectname: myglobalData.pages.dynamicfields.projectname,
    configuration: myglobalData.pages.dynamicfields.configuration,
    candidatessource: myglobalData.pages.dynamicfields.candidatessource,
    candidatestatus: myglobalData.pages.dynamicfields.candidatestatus,
    candidatepost: myglobalData.pages.dynamicfields.candidatepost,
    leadstatus: myglobalData.pages.dynamicfields.leadstatus,

    allusers: myglobalData.pages.user.allusers,
    useranalyst: myglobalData.pages.user.useranalyst,
    adduser: myglobalData.pages.user.adduser,

    addlead: myglobalData.pages.leads.addlead,
    editlead: myglobalData.pages.leads.editlead,
    totalleads: myglobalData.pages.leads.totalleads,
    quickeditlead: myglobalData.pages.leads.quickeditlead,
    nonassignlead: myglobalData.pages.leads.nonassignlead,
    assignleadfrom: myglobalData.pages.leads.assignleadfrom,
    assignlead: myglobalData.pages.leads.assignlead,
    missedlead: myglobalData.pages.leads.missedlead,
    leadbystatus: myglobalData.pages.leads.leadbystatus,
    presentlead: myglobalData.pages.leads.presentlead,
    allstatus: myglobalData.pages.leads.allstatus,
    viewleaddetails: myglobalData.pages.leads.viewleaddetails,

    addcandidate: myglobalData.pages.humanresource.addcandidate,
    allcandidate: myglobalData.pages.humanresource.allcandidate,
    quickeditcandidate: myglobalData.pages.humanresource.quickeditcandidate,
    editcandidate: myglobalData.pages.humanresource.editcandidate,
    assigncandidatefrom: myglobalData.pages.humanresource.assigncandidatefrom,
    viewcandidate: myglobalData.pages.humanresource.viewcandidate,
    assigncandidate: myglobalData.pages.humanresource.assigncandidate,
    nonassigncandidate: myglobalData.pages.humanresource.nonassigncandidate,

    callingreport: myglobalData.pages.report.callingreport,

    profile: myglobalData.pages.other.profile,
    crmsetting: myglobalData.pages.other.crmsetting,
    loader: myglobalData.pages.other.loader,
  };

  function navReducer(state, action) {
    const tempData = { ...myglobalData };
    if (action.event === "updategolbal_userdata") {
      setLocalData("updategolbal_userdata", action.data);
      tempData.user_data = action.data;
      setMyglobalData(tempData);
    } else if (action.event === "setlocaldata") {
      setLocalData(action.key, action.value);
    } else {
      const component = componentConfig[action.event];
      if (component) {
        localStorage.setItem(
          "previousScreen",
          localStorage.getItem("currScreen")
        );
        setTimeout(() => {
          localStorage.setItem("currScreen", action.event);

          tempData.currScreen = component;
          setMyglobalData(tempData);
        }, 1);
        // HandlePreviousScreen(action.event);
      } else {
        console.log("No action");
      }
    }
  }

  const [state, dispatch] = useReducer(navReducer);

  React.useEffect(() => {
    if (appInitialised === false) {
      setPrevScreen(localStorage.getItem("previousScreen"));
      setAppInitialised(true);
      loadDefault();
    }
  }, []);

  return (
    <>
      {appInitialised && (
        <Suspense
          callback={console.log("Loading..")}
          fallback={
            localStorage.getItem("currScreen") === "Login" ? null : (
              <Box sx={{ display: "flex" }}>
                <myglobalData.pages.components.leftsidebar
                  dispatch={dispatch}
                  myglobalData={myglobalData}
                />
                <DrawerHeader />
                <Box component="main" sx={{ flexGrow: 1, px: 3, py: 10 }}>
                  <Skeleton variant="rectangular" width="100%" height={30} />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={300}
                    sx={{ mt: 3 }}
                  />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={300}
                    sx={{ mt: 3 }}
                  />
                </Box>
              </Box>
            )
          }
        >
          {localStorage.getItem("currScreen") === "Login" ? (
            <myglobalData.currScreen
              dispatch={dispatch}
              myglobalData={myglobalData}
            />
          ) : localStorage.getItem("currScreen") === "loader" ? (
            <myglobalData.currScreen
              dispatch={dispatch}
              myglobalData={myglobalData}
            />
          ) : (
            <Box style={{ display: "flex" }}>
              <myglobalData.pages.components.leftsidebar
                dispatch={dispatch}
                myglobalData={myglobalData}
              />
              <DrawerHeader />
              <Box
                component="main"
                sx={{ flexGrow: 1, px: 3, py: 10, overflowX: "hidden" }}
              >
                <myglobalData.currScreen
                  dispatch={dispatch}
                  myglobalData={myglobalData}
                />
              </Box>
            </Box>
          )}
        </Suspense>
      )}
    </>
  );
};

export default Main;
