import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";

import "bootstrap/dist/css/bootstrap.min.css";
import Mui from "./context/Mui";
import Bootstrap from "./context/Bootstrap";
import MyGlobalData from "./context/Global";
import "./App.css";
import Main from "./setting/Main";
import { ReactQueryDevtools } from "react-query/devtools";
// pre-catching
export const queryClient = new QueryClient();

const App = () => {
  const [loading, setLoading] = React.useState(true);
  const spinner = document.getElementById("spinner");

  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 200);
  }

  return (
    !loading && (
      <QueryClientProvider client={queryClient}>
        <Mui>
          <Bootstrap>
            <MyGlobalData>
              <Main />
            </MyGlobalData>
          </Bootstrap>
        </Mui>
        <ReactQueryDevtools initialIsOpen={true} position="bottom-right" />
      </QueryClientProvider>
    )
  );
};

export default App;
